import React, { useEffect, useState } from 'react';
import './App.css';
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { NavLink, Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';
import Home from './Pages/Home/Home';
import AboutMePage from './Pages/About/About';
import ContactUsPage from './Pages/ContactUs/ContactUsPage';
import AppointmentsPage from './Pages/Appointments/Appointments';
import BloodTestsPage from './Pages/Blood/BloodTests';
import DripsPage from './Pages/Drips/Drips';
import Header from './Components/Header/Header';
import WhatsApp from './Components/HoveringActions/WhatsApp';
import BookAppointmentAction from './Components/HoveringActions/BookAppointment';
import { useTranslation } from 'react-i18next';
import PhysioPage from './Pages/Physio/PhysioPage';


const ScrollToTop = () => {
  const { pathname } = useLocation();  // Get the current path

  useEffect(() => {
    window.scrollTo(0, 0);  // Scroll to the top when pathname changes
  }, [pathname]);  // Trigger the effect whenever the route changes

  return null;  // This component doesn't render anything
};

const App: React.FC = () => {
  const { t } = useTranslation();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    window.scrollTo(0, 0); 
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="App">


      <Router>
        <ScrollToTop />
        <div className={`header`}>

          <nav className="navBar">
            <div className="logo">
              <img alt="logo" src="/assets/Images/atlas_logo.svg" />
            </div>

            <div className={`navLinks ${isMenuOpen ? 'open' : ''}`}>
              <NavLink onClick={toggleMenu}
                to="/"
                className={({ isActive }) => (isActive ? "navLink activeLink" : "navLink")}
              >
                {t("homeMenu")}
              </NavLink>
              <NavLink onClick={toggleMenu}
                to="/appointment"
                className={({ isActive }) => (isActive ? "navLink activeLink" : "navLink")}
              >
                {t("appointmentBookingMenu")}
              </NavLink>
              <NavLink onClick={toggleMenu}
                to="/physio" 
                className={({ isActive }) => (isActive ? "navLink activeLink" : "navLink")}
              >
                {t("physioMenu")}
              </NavLink>
              <NavLink onClick={toggleMenu}
                to="/bloodtests"
                className={({ isActive }) => (isActive ? "navLink activeLink" : "navLink")}
              >
                {t("bloodTestsMenu")}
              </NavLink>
              <NavLink onClick={toggleMenu}
                to="/drips"
                className={({ isActive }) => (isActive ? "navLink activeLink" : "navLink")}
              >
                {t("vitDripMenu")}
              </NavLink>
              <NavLink onClick={toggleMenu}
                to="/about"
                className={({ isActive }) => (isActive ? "navLink activeLink" : "navLink")}
              >
                {t("aboutMeMenu")}
              </NavLink>
              <NavLink onClick={toggleMenu}
                to="/contactus"
                className={({ isActive }) => (isActive ? "navLink activeLink" : "navLink")}
              >
                {t("contactUsMenu")}
              </NavLink>
            </div>
          </nav>
          <header className='displayPc'><Header /></header>
        </div>

        <div className='content'>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/appointment" element={<AppointmentsPage />} />
            <Route path="/physio" element={<PhysioPage />} />
            <Route path="/bloodtests" element={<BloodTestsPage />} />
            <Route path="/drips" element={<DripsPage />} />
            <Route path="/about" element={<AboutMePage />} />
            <Route path="/contactus" element={<ContactUsPage />} />
          </Routes>
        </div>
      </Router>


      <div className="hovering-actions">
        <div className="hamburger hovering-button" onClick={toggleMenu}>
          <i className="fa-solid fa-bars"></i>
      </div>
        <WhatsApp phoneNumber="+447584084915" />
        <BookAppointmentAction></BookAppointmentAction>
      </div>
    </div>
  );
}




export default App;
